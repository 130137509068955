import { getString } from '../utils';
import { decompress } from "../compression";
import { TTMCommandType } from './data/scripting';
export function loadTTMResourceEntry(entry) {
  var offset = 0;
  var type = getString(entry.data, offset, 3);

  if (type !== 'VER') {
    throw "Invalid Type ".concat(type, ": expecting header type VER");
  }

  var versionSize = entry.data.getUint32(offset + 4, true);
  var version = getString(entry.data, offset + 8, versionSize); // 4.09

  offset += 8;
  offset += versionSize;
  var block = getString(entry.data, offset, 3);

  if (block !== 'PAG') {
    throw "Invalid Type ".concat(block, ": expecting block type PAG");
  }

  offset += 4;
  var numPages = entry.data.getUint32(offset, true);
  var pagUnknown02 = entry.data.getUint16(offset + 4, true); // Skip unknown fields

  offset += 6;
  block = getString(entry.data, offset, 3);

  if (block !== 'TT3') {
    throw "Invalid Type ".concat(block, ": expecting block type TT3");
  }

  var blockSize = entry.data.getUint32(offset + 4, true);
  var compressionType = entry.data.getUint8(offset + 8, true);
  var uncompressedSize = entry.data.getUint32(offset + 9, true);
  offset += 13;
  blockSize -= 5; // take type and size out of the block

  var compressedData = new DataView(entry.buffer.slice(offset, offset + blockSize));
  offset += blockSize;
  var data = decompress(compressionType, compressedData, 0, compressedData.byteLength);
  data = new DataView(new Int8Array(data).buffer);
  block = getString(entry.data, offset, 3);

  if (block !== 'TTI') {
    throw "Invalid Type ".concat(block, ": expecting block type TTI");
  }

  offset += 4;
  var ttiUnknown01 = entry.data.getUint16(offset, true);
  var ttiUnknown02 = entry.data.getUint16(offset + 2, true); // Skip unknown fields

  offset += 4;
  block = getString(entry.data, offset, 3);

  if (block !== 'TAG') {
    throw "Invalid Type ".concat(block, ": expecting block type TAG");
  }

  offset += 4;
  var tagSize = entry.data.getUint32(offset, true);
  var numTags = entry.data.getUint16(offset + 4, true);
  offset += 6;
  var tags = [];

  for (var t = 0; t < numTags; t++) {
    var id = entry.data.getUint16(offset, true);
    var description = getString(entry.data, offset + 2);
    tags.push({
      id: id,
      description: description
    });
    offset += 2;
    offset += description.length + 1;
  }

  var lineNumber = 1;
  var innerOffset = 0;
  var scripts = [];
  var prevTagId = 0;
  var scenes = [];
  var sceneScripts = [];

  var _loop = function _loop() {
    var opcode = data.getUint16(innerOffset, true);
    innerOffset += 2;
    var size = opcode & 0x000f;
    opcode &= 0xfff0;
    var command = {
      opcode: opcode,
      lineNumber: lineNumber,
      line: null,
      name: null,
      tag: null,
      params: []
    };

    if (opcode == 0x1110 && size === 1) {
      var tagId = data.getUint16(innerOffset, true);
      innerOffset += 2;
      command.tag = tags.find(function (t) {
        return t.id === tagId;
      });

      if (command.tag !== undefined) {
        command.name = "".concat(tagId, ":").concat(command.tag.description);
      } else {
        command.name = " tag[".concat(tagId, "]");
      }

      scenes.push({
        tagId: prevTagId,
        script: sceneScripts
      });
      sceneScripts = []; // reset scene script

      prevTagId = tagId;
    } else if (size === 15) {
      command.name = getString(data, innerOffset);
      innerOffset += command.name.length;

      if (data.getUint8(innerOffset, true) === 0) {
        innerOffset++;
      }

      if (data.getUint8(innerOffset, true) === 0) {
        innerOffset++;
      }

      command.params.push(command.name);
    } else {
      for (var b = 0; b < size; b++) {
        command.params.push(data.getInt16(innerOffset, true));
        innerOffset += 2;
      }
    }

    var type = TTMCommandType.find(function (ct) {
      return ct.opcode === command.opcode;
    });
    command.line = ''; // [0x${c.opcode.toString(16)}] 

    if (type !== undefined) {
      command.line += "".concat(type.command, " ");

      if (command.opcode == 0x1110 && command.name) {
        command.line += command.name.toUpperCase();
      }
    } else {
      command.line = 'UNKNOWN ';
    }

    for (var p = 0; p < command.params.length; p++) {
      command.line += "".concat(command.params[p], " ");
    }

    lineNumber++;
    scripts.push(command);
    sceneScripts.push(command);
  };

  while (innerOffset < uncompressedSize) {
    _loop();
  }

  scenes.push({
    tagId: prevTagId,
    script: sceneScripts
  });
  return {
    name: entry.name,
    type: entry.type,
    numPages: numPages,
    pagUnknown02: pagUnknown02,
    ttiUnknown01: ttiUnknown01,
    ttiUnknown02: ttiUnknown02,
    tags: tags,
    buffer: data,
    scripts: scripts,
    scenes: scenes
  };
}