import { getString } from '../utils';
export function loadPALResourceEntry(entry) {
  var offset = 0;
  var type = getString(entry.data, offset, 3);

  if (type !== 'PAL') {
    throw "Invalid Type ".concat(type, ": expecting header type PAL");
  }

  offset += 4; // skip unknown bytes    

  offset += 4;
  var block = getString(entry.data, offset, 3);

  if (block !== 'VGA') {
    throw "Invalid Type ".concat(block, ": expecting block type VGA");
  }

  offset += 4;
  var palette = []; // read all 256 palette colors

  for (var c = 0; c < 256; c++) {
    var r = entry.data.getUint8(offset + 0, true);
    var g = entry.data.getUint8(offset + 1, true);
    var b = entry.data.getUint8(offset + 2, true);
    offset += 3;
    palette.push({
      index: c,
      a: 255,
      r: r * 4,
      g: g * 4,
      b: b * 4
    });
  }

  return {
    name: entry.name,
    type: type,
    palette: palette
  };
}