import { INDEX_STRING_SIZE, PALETTE } from '../constants';
import { getString } from '../utils';
import { decompress } from "../compression";
export function loadBMPResourceEntry(entry) {
  var offset = 0;
  var type = getString(entry.data, offset, 3);

  if (type !== 'BMP') {
    throw "Invalid Type ".concat(type, ": expecting header type BMP");
  } // info does not seem to be used - also has weird values


  var width = entry.data.getUint16(offset + 4, true);
  var height = entry.data.getUint16(offset + 6, true);
  offset += 8;
  var block = getString(entry.data, offset, 3);

  if (block !== 'INF') {
    throw "Invalid Type ".concat(block, ": expecting block type INF");
  } // const blockSize = entry.data.getUint32(offset + 4, true);


  var numImages = entry.data.getUint16(offset + 8, true);
  offset += 10;
  var images = []; // get width value for all images

  for (var i = 0; i < numImages; i++) {
    var w = entry.data.getUint16(offset, true);
    offset += 2;
    images.push({
      width: w,
      height: 0,
      pixels: [],
      buffer: []
    });
  } // get height value for all images


  for (var _i = 0; _i < numImages; _i++) {
    var h = entry.data.getUint16(offset, true);
    offset += 2;
    images[_i].height = h;
  }

  block = getString(entry.data, offset, 3);

  if (block !== 'BIN') {
    throw "Invalid Type ".concat(block, ": expecting block type BIN");
  }

  var blockSize = entry.data.getUint32(offset + 4, true);
  var compressionType = entry.data.getUint8(offset + 8, true);
  var uncompressedSize = entry.data.getUint32(offset + 9, true);
  offset += 13;
  blockSize -= 5; // take type and size out of the block

  var compressedData = new DataView(entry.buffer.slice(offset, offset + blockSize));
  var data = decompress(compressionType, compressedData, 0, compressedData.byteLength);
  var dataIndex = 0;
  var pixelIndex = 0;

  for (var _i2 = 0; _i2 < numImages; _i2++) {
    var image = images[_i2];

    for (var _h = 0; _h < image.height; _h++) {
      for (var _w = 0; _w < image.width; _w++) {
        var c = data[dataIndex];

        if (pixelIndex % 2 === 0) {
          c = c >> 4;
        } else {
          c = c & 0x0f;
          dataIndex++;
        }

        image.buffer[_w + image.width * _h] = c;
        image.pixels[_w + image.width * _h] = {
          index: c,
          a: PALETTE[c].a,
          r: PALETTE[c].r,
          g: PALETTE[c].g,
          b: PALETTE[c].b
        };
        pixelIndex++;
      }
    }
  }

  return {
    name: entry.name,
    type: type,
    width: width,
    height: height,
    numImages: numImages,
    images: images
  };
}