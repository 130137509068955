/**
 * Converts byte sequence numbers into String
 * @param {*} buffer DataView buffer
 * @param {*} offset Offset of the current DataView buffer
 * @param {*} length Max size of the string
 */
export function getString(buffer, offset) {
  var length = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 100;
  var str = '';

  for (var i = 0; i < length; i++) {
    var char = buffer.getUint8(offset + i);

    if (char === 0) {
      break;
    }

    str += String.fromCharCode(char);
  }

  return str;
}