import { getString } from '../utils';
import { decompress } from "../compression";
import { ADSCommandType } from './data/scripting';
export function loadADSResourceEntry(entry) {
  var offset = 0;
  var type = getString(entry.data, offset, 3);

  if (type !== 'VER') {
    throw "Invalid Type ".concat(type, ": expecting header type VER");
  }

  var versionSize = entry.data.getUint32(offset + 4, true);
  var version = getString(entry.data, offset + 8, versionSize); // 4.09

  offset += 8;
  offset += versionSize;
  var block = getString(entry.data, offset, 3);

  if (block !== 'ADS') {
    throw "Invalid Type ".concat(block, ": expecting block type ADS");
  }

  offset += 4;
  var adsUnknown01 = entry.data.getUint16(offset, true);
  var adsUnknown02 = entry.data.getUint16(offset + 2, true); // Skip unknown fields

  offset += 4;
  block = getString(entry.data, offset, 3);

  if (block !== 'RES') {
    throw "Invalid Type ".concat(block, ": expecting block type RES");
  }

  offset += 4;
  var resSize = entry.data.getUint32(offset, true);
  var numResources = entry.data.getUint16(offset + 4, true); // Skip unknown fields

  offset += 6;
  var resources = [];

  for (var r = 0; r < numResources; r++) {
    var id = entry.data.getUint16(offset, true);
    var name = getString(entry.data, offset + 2);
    resources.push({
      id: id,
      name: name
    });
    offset += 2;
    offset += name.length + 1;
  }

  block = getString(entry.data, offset, 3);

  if (block !== 'SCR') {
    throw "Invalid Type ".concat(block, ": expecting block type SCR");
  }

  var blockSize = entry.data.getUint32(offset + 4, true);
  var compressionType = entry.data.getUint8(offset + 8, true);
  var uncompressedSize = entry.data.getUint32(offset + 9, true);
  offset += 13;
  blockSize -= 5; // take type and size out of the block

  var compressedData = new DataView(entry.buffer.slice(offset, offset + blockSize));
  offset += blockSize;
  var data = decompress(compressionType, compressedData, 0, compressedData.byteLength);
  data = new DataView(new Int8Array(data).buffer);
  block = getString(entry.data, offset, 3);

  if (block !== 'TAG') {
    throw "Invalid Type ".concat(block, ": expecting block type TAG");
  }

  offset += 4;
  var tagSize = entry.data.getUint32(offset, true);
  var numTags = entry.data.getUint16(offset + 4, true);
  offset += 6;
  var tags = [];

  for (var t = 0; t < numTags; t++) {
    var _id = entry.data.getUint16(offset, true);

    var description = getString(entry.data, offset + 2);
    tags.push({
      id: _id,
      description: description
    });
    offset += 2;
    offset += description.length + 1;
  }

  var lineNumber = 1;
  var indent = 0;
  var innerOffset = 0;
  var prevTagId = 0;
  var scripts = [];
  var scenes = [];
  var sceneScripts = [];

  var _loop = function _loop() {
    var opcode = data.getUint16(innerOffset, true);
    innerOffset += 2;
    var command = {
      opcode: opcode,
      lineNumber: lineNumber,
      line: '',
      indent: 0,
      tag: null,
      params: []
    };
    var c = ADSCommandType.find(function (ct) {
      return ct.opcode === opcode;
    });

    if (c !== undefined && opcode > 0x100) {
      var size = c.paramSize;
      command.line += "".concat(c.command, " ");

      for (var b = 0; b < size; b++) {
        var param = data.getInt16(innerOffset, true);
        command.params.push(param);
        innerOffset += 2;
        command.line += "".concat(param, " ");
      }

      command.indent = indent;

      if (c.indent !== null) {
        indent += c.indent;
      }

      if (c.indent < 0) {
        command.indent = indent;
      }

      if (c.indent === 0) {
        command.indent = 0;

        while (indent--) {
          scripts.push({
            opcode: 0xfff0,
            lineNumber: lineNumber++,
            line: 'END_IF',
            indent: indent,
            tag: null,
            params: []
          });
        }

        indent = 0;
        command.lineNumber = lineNumber;
      }

      sceneScripts.push(command);
    } else {
      command.tag = tags.find(function (t) {
        return t.id === command.opcode;
      });
      command.line += "".concat(command.tag.description);
      command.indent = 0;
      indent = 0;

      if (prevTagId) {
        scenes.push({
          tagId: prevTagId,
          script: sceneScripts
        });
      }

      sceneScripts = []; // reset scene script

      prevTagId = command.tag;
    }

    lineNumber++;
    scripts.push(command);
  };

  while (innerOffset < uncompressedSize) {
    _loop();
  }

  return {
    name: entry.name,
    type: entry.type,
    adsUnknown01: adsUnknown01,
    adsUnknown02: adsUnknown02,
    resources: resources,
    tags: tags,
    buffer: data,
    scripts: scripts,
    scenes: scenes
  };
}