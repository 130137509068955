function getBits(data, offset, numBits, current, nextBit) {
  var value = 0,
      innerOffset = 0;

  if (numBits === 0) {
    return {
      value: 0,
      innerOffset: 0,
      c: current,
      nb: nextBit
    };
  }

  for (var b = 0; b < numBits; b++) {
    if ((current & 1 << nextBit) !== 0) {
      value += 1 << b;
    }

    nextBit++;

    if (nextBit > 7) {
      if (offset + innerOffset >= data.byteLength) {
        current = 0;
      } else {
        current = data.getUint8(offset + innerOffset, true);
        innerOffset++;
      }

      nextBit = 0;
    }
  }

  return {
    value: value,
    innerOffset: innerOffset,
    c: current,
    nb: nextBit
  };
}

;
export function decompressLZW(data, offset, length) {
  var pdata = [];
  var decodeStack = [];
  var codeTable = [];
  var numBits = 9;
  var freeEntry = 257;
  var nextBit = 0,
      stackIndex = 0,
      bitPos = 0;
  var current = data.getUint8(offset++, true);

  var _getBits = getBits(data, offset, numBits, current, nextBit),
      value = _getBits.value,
      innerOffset = _getBits.innerOffset,
      c = _getBits.c,
      nb = _getBits.nb;

  var oldCode = value;
  nextBit = nb;
  current = c;
  offset += innerOffset;
  var lastByte = oldCode;
  pdata.push(oldCode);

  try {
    while (offset < length) {
      var _getBits2 = getBits(data, offset, numBits, current, nextBit),
          _value = _getBits2.value,
          _innerOffset = _getBits2.innerOffset,
          _c = _getBits2.c,
          _nb = _getBits2.nb;

      var newCode = _value;
      nextBit = _nb;
      current = _c;
      offset += _innerOffset;
      bitPos += numBits;

      if (newCode === 256) {
        var numBits3 = numBits << 3;
        var numSkip = numBits3 - (bitPos - 1) % numBits3 - 1;

        var _getBits3 = getBits(data, offset, numSkip, current, nextBit),
            _value2 = _getBits3.value,
            _innerOffset2 = _getBits3.innerOffset,
            _c2 = _getBits3.c,
            _nb2 = _getBits3.nb;

        nextBit = _nb2;
        current = _c2;
        offset += _innerOffset2;
        numBits = 9;
        freeEntry = 256;
        bitPos = 0;
      } else {
        var code = newCode;

        if (code >= freeEntry) {
          if (stackIndex >= 4096) {
            break;
          }

          decodeStack[stackIndex] = lastByte;
          stackIndex++;
          code = oldCode;
        }

        while (code >= 256) {
          if (code > 4095) {
            break;
          }

          decodeStack[stackIndex] = codeTable[code].append;
          stackIndex++;
          code = codeTable[code].prefix;
        }

        decodeStack[stackIndex] = code;
        stackIndex++;
        lastByte = code;

        while (stackIndex > 0) {
          stackIndex--;
          pdata.push(decodeStack[stackIndex]);
        }

        if (freeEntry < 4096) {
          codeTable[freeEntry] = {
            prefix: oldCode,
            append: lastByte
          };
          freeEntry++;

          if (freeEntry >= 1 << numBits && numBits < 12) {
            numBits++;
            bitPos = 0;
          }
        }

        oldCode = newCode;
      }
    }
  } catch (error) {
    console.error(error);
  }

  return pdata;
}