export function decompressRLE(data, offset, length) {
  var pdata = [];

  while (offset < length) {
    var control = data.getUint8(offset++, true);

    if ((control & 0x80) === 0x80) {
      var len = control & 0x7F;
      var value = data.getUint8(offset++, true);

      for (var i = 0; i < len; i++) {
        pdata.push(value);
      }
    } else {
      for (var _i = 0; _i < control; _i++) {
        pdata.push(data.getUint8(offset++, true));
      }
    }
  }

  return pdata;
}