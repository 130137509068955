import { INDEX_STRING_SIZE, PALETTE } from '../constants';
import { getString } from '../utils';
import { decompress } from "../compression";
export function loadSCRResourceEntry(entry) {
  var offset = 0;
  var type = getString(entry.data, offset, 3);

  if (type !== 'SCR') {
    throw "Invalid Type ".concat(type, ": expecting header type SCR");
  } // info does not seem to be used - also has weird values


  var totalSize = entry.data.getUint16(offset + 4, true);
  var flags = entry.data.getUint16(offset + 6, true);
  offset += 8;
  var block = getString(entry.data, offset, 3);

  if (block !== 'DIM') {
    throw "Invalid Type ".concat(block, ": expecting block type DIM");
  }

  var blockSize = entry.data.getUint32(offset + 4, true);
  var width = entry.data.getUint16(offset + 8, true);
  var height = entry.data.getUint16(offset + 10, true);
  offset += 12;
  block = getString(entry.data, offset, 3);

  if (block !== 'BIN') {
    throw "Invalid Type ".concat(block, ": expecting block type BIN");
  }

  blockSize = entry.data.getUint32(offset + 4, true);
  var compressionType = entry.data.getUint8(offset + 8, true);
  var uncompressedSize = entry.data.getUint32(offset + 9, true);
  offset += 13;
  blockSize -= 5; // take type and size out of the block

  var compressedData = new DataView(entry.buffer.slice(offset, offset + blockSize));
  var data = decompress(compressionType, compressedData, 0, compressedData.byteLength);
  var numImages = 1;
  var images = [{
    width: width,
    height: height,
    buffer: [],
    pixels: []
  }];
  var image = images[0];
  var dataIndex = 0;
  var pixelIndex = 0;

  for (var h = 0; h < height; h++) {
    for (var w = 0; w < width; w++) {
      var c = data[dataIndex];

      if (pixelIndex % 2 === 0) {
        c = c >> 4;
      } else {
        c = c & 0x0f;
        dataIndex++;
      }

      var pal = PALETTE[c];
      image.buffer[w + image.width * h] = c;
      image.pixels[w + image.width * h] = {
        index: c,
        a: pal.a,
        r: pal.r,
        g: pal.g,
        b: pal.b
      };
      pixelIndex++;
    }
  }

  return {
    name: entry.name,
    type: type,
    totalSize: totalSize,
    flags: flags,
    width: width,
    height: height,
    numImages: numImages,
    images: images
  };
}