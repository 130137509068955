export var INDEX_STRING_SIZE = 12;
export var PALETTE = [{
  a: 0,
  r: 168,
  g: 0,
  b: 168
}, {
  a: 255,
  r: 0,
  g: 0,
  b: 168
}, {
  a: 255,
  r: 0,
  g: 168,
  b: 0
}, {
  a: 255,
  r: 0,
  g: 168,
  b: 168
}, {
  a: 255,
  r: 168,
  g: 0,
  b: 0
}, {
  a: 255,
  r: 0,
  g: 0,
  b: 0
}, {
  a: 255,
  r: 168,
  g: 168,
  b: 0
}, {
  a: 255,
  r: 212,
  g: 212,
  b: 212
}, {
  a: 255,
  r: 128,
  g: 128,
  b: 128
}, {
  a: 255,
  r: 0,
  g: 0,
  b: 255
}, {
  a: 255,
  r: 0,
  g: 255,
  b: 0
}, {
  a: 255,
  r: 0,
  g: 255,
  b: 255
}, {
  a: 255,
  r: 255,
  g: 0,
  b: 0
}, {
  a: 255,
  r: 255,
  g: 0,
  b: 255
}, {
  a: 255,
  r: 255,
  g: 255,
  b: 0
}, {
  a: 255,
  r: 255,
  g: 255,
  b: 255
}];