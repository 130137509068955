import { INDEX_STRING_SIZE } from '../constants';
import { getString } from '../utils';
import { ResourceType } from './data/types';
var INDEX_HEADER_SIZE = 6;
/**
 * Load all Resource details based on index resource file
 * @param {*} filepath Full path of the file
 * @param {*} filename File name
 */

export function loadResources(buffer, resbuffer) {
  var offset = 0; // current resource offest

  var resources = []; // list of resource files

  var data = new DataView(buffer, offset, buffer.byteLength);
  var header = {
    unk0: data.getUint8(offset, true),
    unk1: data.getUint8(offset + 1, true),
    // number of entries?
    unk2: data.getUint8(offset + 2, true),
    unk3: data.getUint8(offset + 3, true),
    numResources: data.getUint8(offset + 4, true),
    unk5: data.getUint8(offset + 5, true)
  };
  offset += INDEX_HEADER_SIZE; // Read resource files and entries
  // Read number of resource files (castaway only uses a single one)

  for (var r = 0; r < header.numResources; r++) {
    var innerOffset = offset;
    var res = {
      name: getString(data, innerOffset, INDEX_STRING_SIZE),
      numEntries: data.getUint16(innerOffset + 13, true),
      size: 0,
      entries: []
    };
    resources.push(res);
    innerOffset += 15;
    res.size = resbuffer.byteLength;
    var resData = new DataView(resbuffer, 0, res.size);

    for (var e = 0; e < res.numEntries; e++) {
      // from index
      var entrySize = data.getUint16(innerOffset, true); // uncompressed size

      var entryOffset = data.getUint32(innerOffset + 4, true); // from resource

      var name = getString(resData, entryOffset, INDEX_STRING_SIZE);
      var entryCompressedSize = resData.getUint32(entryOffset + 13, true);
      var startOffset = entryOffset + 17;
      var endOffset = startOffset + entryCompressedSize;
      var entry = {
        name: name,
        type: name.split('.')[1],
        size: entrySize,
        // uncompressed size
        offset: entryOffset,
        compressedSize: entryCompressedSize,
        buffer: resbuffer.slice(startOffset, endOffset),
        data: new DataView(resbuffer, startOffset, entryCompressedSize)
      };
      innerOffset += 8;
      res.entries.push(entry);
    }
  }

  return {
    header: header,
    resources: resources
  };
}
export function loadResourceEntry(entry) {
  var resType = ResourceType.find(function (r) {
    return r.type === entry.type;
  });
  return resType.callback(entry);
}